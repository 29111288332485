import { Head } from '../../../components/Head';
import { SnackTitle } from '../../../components/SnackTitle';
import { ImageGallery } from './style';

import carregadorPromo from '../../../assets/carregadores-promo.png';
import carregadores from '../../../assets/carregadores.png';
import carregadorPortatil from '../../../assets/carregador-portatil.png';

export default function Pizzas() {
  return (
    <>
      <Head title='Cabo USB para Celular - Melhores Preços e Qualidade' />
      <SnackTitle>Carregador para Celulares</SnackTitle>

      <h2>Compre Cabos USB de Alta Qualidade para Seu Celular</h2>
      <p>Oferecemos uma variedade de cabos USB compatíveis com as principais marcas de celulares. Garantia de qualidade e durabilidade com preços acessíveis. Confira nossas ofertas!</p>

      <ImageGallery>
        <img src={carregadorPromo} alt="Temos cabos para todas as marcas de celulares" />
        <img src={carregadorPortatil} alt="Cabo USB reforçado com garantia de 1 ano" />
        <img src={carregadores} alt="Cabos originais para celular com certificação de qualidade" />
      </ImageGallery>

      <h3>Por que Escolher Nossos Cabos USB?</h3>
      <ul>
        <li>Reforçados e duráveis</li>
        <li>Carregamento rápido e eficiente</li>
        <li>Compatíveis com diversas marcas</li>
        <li>Preços a partir de R$19,99</li>
      </ul>

      <p>Não perca tempo! Confira nossas ofertas e garanta já seu cabo USB de alta qualidade para seu celular.</p>
    </>
  );
}