import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from './style';

export function Footer() {
    return (
        <Container>
            <nav>
                <ul>


                    <li>
                        <NavLink to="/about-us">Sobre Nós</NavLink>
                    </li>
                </ul>
            </nav>
        </Container>
    );
}