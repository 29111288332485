import { Head } from '../../../components/Head';
import { SnackTitle } from '../../../components/SnackTitle';
import { ImageGallery } from './style';

import telaQuebrou from '../../../assets/telaquebrou.png';
import telaquebrou2 from '../../../assets/telaquebrou-2.png';
import telaquebrou3 from '../../../assets/telaquebrou-3.png';

export default function Pizzas() {
    return (
        <>
            <Head title='Telas para Celulares - Qualidade e Durabilidade' />
            <SnackTitle>Telas para Celulares</SnackTitle>

            <h2>Compre Telas de Alta Qualidade para Seu Celular</h2>
            <p>Oferecemos uma ampla variedade de telas para celulares das principais marcas. Nossas telas são resistentes, duráveis e proporcionam uma excelente experiência visual. Aproveite nossos preços acessíveis!</p>

            <ImageGallery>
                <img src={telaQuebrou} alt="Tela de celular quebrada pronta para substituição" />
                <img src={telaquebrou2} alt="Telas de alta qualidade para reparo de celulares" />
                <img src={telaquebrou3} alt="Substituímos telas para todas as marcas de celulares" />
            </ImageGallery>

            <h3>Por que Escolher Nossas Telas para Celulares?</h3>
            <ul>
                <li>Resistentes e de alta durabilidade</li>
                <li>Excelente resolução e qualidade de imagem</li>
                <li>Compatíveis com diversas marcas e modelos</li>
                <li>Preços a partir de R$99,99</li>
            </ul>

            <p>Garanta já a substituição da sua tela com produtos de qualidade. Não deixe de conferir nossas ofertas e dê uma nova vida ao seu celular!</p>
        </>
    );
}
