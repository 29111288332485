import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 1rem 0;

  /* Torna o footer fixo no rodapé */
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;

  nav {
    max-width: 1200px;
    margin: 0 auto;

    ul {
      display: flex;
      justify-content: center;
      gap: 2rem;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        font-size: 1rem;
        font-weight: 500;
        transition: color 0.3s;

        &:hover {
          color: ${({ theme }) => theme.colors.yellow};
        }

        &.active {
          color: ${({ theme }) => theme.colors.yellow};
        }
      }
    }
  }

  /* Esconder o footer em dispositivos móveis */
  @media (max-width: 720px) {
    display: none;
  }
`;
