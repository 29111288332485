import styled from 'styled-components';

export const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Controls the spacing between images */
  margin-top: 20px;

  img {
    flex: 1 1 calc(33.333% - 20px); /* Each image takes up one-third of the horizontal space */
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* For smaller screens (mobile) */
  @media (max-width: 768px) {
    img {
      flex: 1 1 100%; /* Images take full width on mobile */
    }
  }
`;