import React from 'react';
import { Head } from '../../../components/Head';
import { SnackTitle } from '../../../components/SnackTitle';
import { ContentWithImage, ImageGallery } from './style';

import assistenciaTecnicaCelular from '../../../assets/assistencia-tecnica-celular.png';
import bateriaViciada from '../../../assets/bateria-viciada.png';
import carregadores from '../../../assets/carregadores.png';
import mobiles from '../../../assets/mobiles.png';
import lento from '../../../assets/lento.png';
import telaQuebrou from '../../../assets/telaquebrou.png';
import tecnologiaBoldBrancoeAzul from '../../../assets/tecnologia-bold-branco-e-azul.png';

const MaintenancePage: React.FC = () => {
    return (
        <>
            <Head title="Manutenção Celulares" />

            <ContentWithImage>
                <div className="text-content">
                    <SnackTitle>Manutenção Rápida e Confiável para Seu Celular</SnackTitle>
                    <h2>
                        Especialistas em Consertos de Smartphones em Matão - SP | Serviços Garantidos, Peças
                        Originais e Atendimento Rápido
                    </h2>
                    <p>
                        Seu celular quebrou? Não se preocupe! Oferecemos consertos rápidos, confiáveis e com
                        garantia. Seja tela quebrada, bateria que não carrega ou problemas de software, nossos
                        técnicos especializados estão prontos para resolver. Visite-nos e tenha seu smartphone de
                        volta como novo no mesmo dia.
                    </p>
                </div>
                <div className="image-content">
                    <img src={mobiles} alt="Celulares" />
                </div>
            </ContentWithImage>

            <ImageGallery>
                <img src={lento} alt="Conserto de celular 1" />
                <img src={assistenciaTecnicaCelular} alt="Conserto de celular 1" />
                <img src={bateriaViciada} alt="Conserto de celular 2" />
                <img src={carregadores} alt="Conserto de celular 3" />
                <img src={telaQuebrou} alt="Conserto de celular 3" />
                <img src={tecnologiaBoldBrancoeAzul} alt="Conserto de celular 3" />
            </ImageGallery>
        </>
    );
};

export default MaintenancePage;
