import { Head } from '../../../components/Head';
import { SnackTitle } from '../../../components/SnackTitle';
import { ImageGallery } from './style';

import carregadordeCelularEstragado from '../../../assets/carregador-de-celular-estragado.png';
import tecnologiaBoldBrancoeAzul from '../../../assets/tecnologia-bold-branco-e-azul.png';
import cabosOriginais from '../../../assets/cabos-originais.png';

export default function Pizzas() {
    return (
        <>
            <Head title='Cabo USB para Celular - Melhores Preços e Qualidade' />
            <SnackTitle>Cabo USB para Celular</SnackTitle>

            <h2>Compre Cabos USB de Alta Qualidade para Seu Celular</h2>
            <p>Oferecemos uma variedade de cabos USB compatíveis com as principais marcas de celulares. Garantia de qualidade e durabilidade com preços acessíveis. Confira nossas ofertas!</p>

            <ImageGallery>
                <img src={tecnologiaBoldBrancoeAzul} alt="Cabo USB reforçado com garantia de 1 ano" />
                <img src={cabosOriginais} alt="Cabos originais para celular com certificação de qualidade" />
                <img src={carregadordeCelularEstragado} alt="Temos cabos para todas as marcas de celulares" />
            </ImageGallery>

            <h3>Por que Escolher Nossos Cabos USB?</h3>
            <ul>
                <li>Reforçados e duráveis</li>
                <li>Carregamento rápido e eficiente</li>
                <li>Compatíveis com diversas marcas</li>
                <li>Preços a partir de R$19,99</li>
            </ul>

            <p>Não perca tempo! Confira nossas ofertas e garanta já seu cabo USB de alta qualidade para seu celular.</p>
        </>
    );
}
