import styled from 'styled-components';

export const ContentWithImage = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;

  .text-content {
    flex: 0 0 50%;
    margin-right: 20px;

    h2 {
      margin-bottom: 20px; /* Adiciona espaço entre o h2 e o parágrafo */
    }
  }

  .image-content {
    flex: 0 0 50%;

    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  /* Design responsivo para telas menores */
  @media (max-width: 768px) {
    flex-direction: column;

    .text-content,
    .image-content {
      flex: 1 1 100%;
      margin-right: 0;
    }
  }
`;

// Mantenha o seu componente ImageGallery existente
export const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Controla o espaçamento entre as imagens */
  margin-top: 20px;

  img {
    flex: 1 1 calc(33.333% - 20px); /* Cada imagem ocupa um terço do espaço horizontal */
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  /* Para telas menores (mobile) */
  @media (max-width: 768px) {
    img {
      flex: 1 1 100%; /* Imagens ocupam a largura total no mobile */
    }
  }
`;
