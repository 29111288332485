import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import { Container } from './styles'

//import { ReactComponent as BurgerIcon } from '../../assets/burger.svg'
//import { ReactComponent as PizzaIcon } from '../../assets/pizza.svg'
//import { ReactComponent as SodaPopIcon } from '../../assets/soda.svg'
//import { ReactComponent as IceCreamIcon } from '../../assets/ice-cream.svg'
import { ReactComponent as MobileScreen } from '../../assets/mobile-screen-c.svg'
import { ReactComponent as MobileRepair } from '../../assets/mobile-repair.svg'
import { ReactComponent as PhoneBattery } from '../../assets/phone-battery.svg'
import { ReactComponent as PhoneCharger } from '../../assets/phone-charger.svg'
import { ReactComponent as PhoneUsbCable } from '../../assets/phone-usb-cable.svg'

import menuImg from '../../assets/menu.svg'

export function Sidebar() {
  const [menuOpen, setMenuOpen] = useState(false)

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  return (
    <Container isMenuOpen={menuOpen}>
      <button type='button' onClick={handleToggleMenu}>
        <img src={menuImg} alt='Abrir e fechar o menu' />
      </button>
      <nav>
        <ul>
          <li>
            <NavLink to='/'>
              <MobileRepair />
              <span>Manutenção Celular</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='mobile-screen'>
              <MobileScreen />
              <span>Tela Celular</span>
            </NavLink>
          </li>

          <li>
            <NavLink to='phone-battery'>
              <PhoneBattery />
              <span>Bateria de Celular</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='phone-charger'>
              <PhoneCharger />
              <span>Carregador de Celular</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='phone-usb-cable'>
              <PhoneUsbCable />
              <span>Carregador de Celular</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </Container>
  )
}