import React from 'react';
import { Head } from '../../../components/Head';
import { SnackTitle } from '../../../components/SnackTitle';
import { ContentWithImage, ImageGallery } from './style';

import assistenciaTecnicaCelular from '../../../assets/assistencia-tecnica-celular.png';
import bateriaViciada from '../../../assets/bateria-viciada.png';
import carregadores from '../../../assets/carregadores.png';
import loja from '../../../assets/loja.jpg';

const MaintenancePage: React.FC = () => {
    return (
        <>
            <Head title="Paulinho Acessórios para Celulares" />

            <ContentWithImage>
                <div className="text-content">
                    <SnackTitle>Bem-vindo a Paulinho Acessórios para Celulares</SnackTitle>
                    <h2>
                        Confiabilidade e Qualidade em Manutenção de Celulares e Acessórios
                    </h2>
                    <p>
                        Nossa loja está aberta há mais de 16 anos, atendendo Matão e toda a região com excelência e
                        confiança, oferecendo um atendimento completo e especializado para nossos clientes.
                    </p>
                    <p>
                        A equipe da Paulinho Acessórios está preparada para oferecer uma ampla variedade de acessórios,
                        incluindo capas e películas para celulares e tablets. Com uma linha completa de antenas rurais,
                        celulares de mesa, roteadores e peças para celulares e tablets, estamos prontos para atender
                        todas as suas necessidades de tecnologia.
                    </p>
                    <p>
                        Além disso, você encontrará em nossa loja uma seleção diversificada de carrinhos e caminhões
                        em miniatura, além de muitas outras novidades.
                    </p>
                    <h2>
                        Conserto de Celulares e Tablets com Garantia
                    </h2>
                    <p>
                        Contamos com uma equipe técnica especializada para conserto de aparelhos celulares e tablets.
                        Trabalhamos com celulares novos e seminovos, todos com garantia, proporcionando a melhor
                        experiência de compra e manutenção.
                    </p>
                    <p>
                        Venha nos visitar e conheça nosso atendimento diferenciado! E para maior comodidade, enviamos
                        nossos produtos para todo o Brasil.
                    </p>
                </div>
                <div className="image-content">
                    <img src={loja} alt="Celulares" />
                </div>
            </ContentWithImage>

            <ImageGallery>
                <img src={assistenciaTecnicaCelular} alt="Conserto de celular 1" />
                <img src={bateriaViciada} alt="Conserto de celular 2" />
                <img src={carregadores} alt="Conserto de celular 3" />
            </ImageGallery>
        </>
    );
};

export default MaintenancePage;